import React from "react"
import "./not-found.scss"
import { Link } from "gatsby"
import { CircleArrow } from "../components/elements/svg-elements"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../components/shared/img"

const NotFound: React.FC = () => {
  const { CONTENT } = useStaticQuery(graphql`
    query {
      CONTENT: wpNotFoundSetting(locale: { locale: { eq: "de_DE" } }) {
        notFoundOptions {
          button
          title
          image {
            altText
            sizes
            sourceUrl
            srcSet
          }
        }
      }
    }
  `)

  const { notFoundOptions } = CONTENT

  return (
    <div className="not-found">
      <div className="not-found__wrapper">
        <Image imgProps={notFoundOptions?.image} />
      </div>
      <h3>{notFoundOptions?.title}</h3>
      <Link to="/">
        <CircleArrow />
        {notFoundOptions?.button}
      </Link>
    </div>
  )
}

export default NotFound
